
.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;
    margin-bottom: 20px;
}
.subTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 68px;
}
.sec1 p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}
.sec1 h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
}
.button {
    margin-top: 20px !important;
    background-color: #021228;
    padding: 10px 20px !important;
    border-radius: 5px !important;
    border: none !important;
    color: #fff !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    width: 200px !important;
}
.button:hover {
    background-color: #0e71f8;
    padding: 10px 20px !important;
    border-radius: 5px;
    border: none;
    color: #021228 !important;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    width: 200px;
}