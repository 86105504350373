/* @import url('https://fonts.cdnfonts.com/css/recoleta');
@import url('https://fonts.cdnfonts.com/css/poppins'); */

.sidebar {
    height: 100vh;
    font-family: 'Roboto';
    background-color: #ececec;
    padding: 50px 0px;
    width: 20vw;
}
.brandName1 {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 25px;
    color: #0e71f8;
    vertical-align: bottom;
}
.brandName {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 25px;
    color: #021228;
    vertical-align: bottom;
}
.sidebar .brand {
    text-decoration: none;
    margin-bottom: 100px !important;
    margin-left: 50px;
}
.header {
    text-decoration: none;
}
.sidebar .leftLinks {
    text-decoration: none;
    margin-top: 15px;
    color: #021228;
    padding: 15px 80px;
    font-weight: 500;
    font-size: 18px;
    display: block;
}
.sidebar .leftLinks:hover, .sidebar .leftLinksActive {
    text-decoration: none;
    margin-top: 15px;
    padding: 15px 80px;
    font-weight: 500;
    font-size: 18px;
    display: block;
    text-decoration: none;
    background-color: #FFFFFF;
    color: #0e71f8;
}
.headerNav {
    width: 10vw;
    vertical-align: middle !important;
}
.headerNav svg {
    font-size: 30px;
}
.contentArea {
    width: 80vw;
    padding: 50px;
    background-color: #f7f5f5;
}
.contentArea h2 {
    width: 80vw;
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 500;
}
.contentArea h1 {
    font-family: 'Roboto';
    font-size: 55px;
    font-weight: 500;
}
.contentArea button {
    margin-top: 50px;
    background-color: #021228;
    padding: 10px 20px !important;
    border-radius: 5px;
    border: none;
    color: #fff !important;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    width: 200px;
}
.contentArea button:hover {
    background-color: #0e71f8;
    padding: 10px 20px !important;
    border-radius: 5px;
    border: none;
    color: #021228 !important;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    width: 200px;
}
.mobileHeader {
    display: none;
}
.desktopHeader {
    display: block;
}
.dropdownLink {
    text-decoration: none;
    color: #021228;
}
.formLabel {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.005em;
    color: #929292;
    position: inherit;
}
.onboardingForm {
    padding: 20px 50px 20px 0px;
}
.inputField {
    font-size: 14px;
    font-family: 'Roboto';
    padding: 12px 20px !important;
    border: #021228 1px solid !important;
    outline: none !important;
}
.pass {
    margin: -40px 20px 0px 0px;
    text-align: right;
    width:40px;
    float: right;
}
.passValid {
    color: #EC5D5D;
    font-size: 10px;
}


@media only screen and (max-width: 600px) and (min-width: 300px) {
    .sidebar {
        display: none;
    }
    .mobileHeader {
        display: block;
    }
    .desktopHeader {
        display: none;
    }
    .contentArea {
        width: 100vw;
        padding: 20px;
        background-color: #f7f5f5;
    }
    .headerNav {
        width: 40vw;
        padding: 0;
        margin-top: 5px;
        text-align: right;
        vertical-align: top;
    }
    .headerNav svg {
        font-size: 30px;
    }
    .contentArea h2 {
        width: 60vw;
        font-family: 'Roboto';
        font-size: 30px;
        font-weight: 500;
    }
}