.adminbg {
    background-image: url('../assets/adminbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    padding: 8% 0%;
}

.detailsModal {
    font-family: 'Poppins';
  }

.detailsModal table span {
    font-size: 15px;
}
/* .brandName1 {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 25px;
    color: #0e71f8;
    vertical-align: bottom;
}
.brandName {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 25px;
    color: #021228;
    vertical-align: bottom;
}
.sidebar .brand {
    text-decoration: none;
    margin-bottom: 100px !important;
    margin-left: 50px;
}
.header {
    text-decoration: none;
}
.sidebar .leftLinks {
    text-decoration: none;
    margin-top: 15px;
    color: #021228;
    padding: 15px 80px;
    font-weight: 500;
    font-size: 18px;
    display: block;
}
.sidebar .leftLinks:hover, .sidebar .leftLinksActive {
    text-decoration: none;
    margin-top: 15px;
    padding: 15px 80px;
    font-weight: 500;
    font-size: 18px;
    display: block;
    text-decoration: none;
    background-color: #FFFFFF;
    color: #0e71f8;
}
.headerNav {
    width: 10vw;
    vertical-align: middle !important;
}
.headerNav svg {
    font-size: 30px;
}
.contentArea {
    width: 80vw;
    padding: 0px 50px 0px 50px;
    background-color: #f7f5f5;
    font-family: 'Roboto' !important;
}
.contentArea h2 {
    width: 80vw;
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 500;
}
.contentArea h1 {
    font-family: 'Poppins';
    font-size: 55px;
    font-weight: 500;
}
.userslist {
    background-color: #fff;
    padding: 20px 40px 40px 40px;
}
.tab {
    background-color: #0e71f8;
    color: #000;
    border: none;
    border-bottom: 2px solid #000;
    font-size: 25px;
    font-weight: bold;
    font-family: 'Poppins';
    margin: 5px 5px 10px 5px;
    padding: 5px 40px;
}
.activetab {
    background-color: transparent;
    color: #000;
    border: none;
    border-bottom: 2px solid #000;
    font-size: 25px;
    font-weight: bold;
    font-family: 'Poppins';
    margin: 5px 5px 10px 5px;
    padding: 5px 40px;
}
.backButton {
    background-color: #021228;
    padding: 10px 20px !important;
    border-radius: 5px;
    border: none;
    color: #fff !important;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 18px;
    margin-top: 30px;
    width: 200px;
}
.onboardingForm {
    padding: 30px;
}
.onboardingForm h3 {
    font-size: 40px;
    font-family: 'Poppins';
    margin-bottom: 10px;
}
.onboardingForm h5 {
    font-size: 25px;
    font-family: 'Poppins';
    margin-bottom: 20px;
}
.inputField {
    font-size: 14px;
    font-family: 'Poppins';
    padding: 12px 20px !important;
    border: #021228 1px solid !important;
    outline: none !important;
}
.formTag {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #C5C5C5;
}
.mobileHeader {
    display: none;
}
.desktopHeader {
    display: block;
}
.dropdownLink {
    text-decoration: none;
    color: #021228;
}
.activate {
    background-color: #0e71f8;
    padding: 5px 15px;
    color: #FFF;
    font-family: 'Roboto';
    font-size: 14px;
    border: none;
}
.deactivate {
    background-color: #000;
    padding: 5px 15px;
    color: #FFF;
    font-family: 'Roboto';
    font-size: 14px;
    border: none;
}
.delete {
    background-color: #000;
    padding: 5px 15px;
    margin-left: 10px;
    color: #FFF;
    font-family: 'Roboto';
    font-size: 14px;
    border: none;
}

.userslist ul {
    list-style-type: none;
    display: flex;
    padding: 0;
}
.userslist ul li {
    padding: 0px 20px 0px 0px;
}
.inputField {
    font-size: 14px;
    font-family: 'Roboto';
    padding: 5px 10px !important;
    border-radius: 5px;
    border: #021228 1px solid !important;
    outline: none !important;
}

@media only screen and (max-width: 400px) and (min-width: 200px) {
    .contentArea {
        width: 100vw;
        padding: 50px 20px 0px 20px;
        background-color: #f7f5f5;
    }
    .sidebar {
        display: none;
    }
    .mobileHeader {
        display: block;
    }
    .desktopHeader {
        display: none;
    }
} */