body {
  margin: 0;
  font-family: 'Roboto' !important;
}

p {
  font-size: 18px;
  font-weight: 300;
}
a {
  font-size: 18px;
}
span {
  font-size: 18px;
}
.form-control {
  font-size: 18px;
  font-weight: 300 !important;
}

.text-primary {
  color: #0e71f8;
  font-weight: 500;
}

.text-secondary {
  color: #052B61;
}

.primary-bg {
  background-color: #0e71f8;
}

.secondary-bg {
  background-color: #052B61;
}

.tertiary-bg {
  background-color: #15c7f822;
}

.form-control {
  font-size: 28px;
}

.select__control, .select__control:hover, .select__control:active{
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #000 !important;
  padding: 6px !important;
  outline: none !important;
}

.primary-btn-1 {
  background-color: #0e71f8;
  border-radius: 10px;
  padding: 10px 40px;
  color: #FFFFFF;
  transition: all .20s ease-in;
  border: none;
}

.primary-btn-1:hover {
  background-color: #D5E6FF;
  border-radius: 10px;
  padding: 10px 40px;
  color: #0e71f8;
  transition: all .20s ease-in;
  border: none;
}

.primary-btn {
  background-color: #0e71f8;
  border-radius: 30px;
  padding: 10px 40px;
  color: #FFFFFF;
  transition: all .20s ease-in;
  border: none;
}

.primary-btn:hover {
  background-color: #D5E6FF;
  border-radius: 30px;
  padding: 10px 40px;
  color: #0e71f8;
  transition: all .20s ease-in;
  border: none;
}

.secondary-btn {
  background-color: #052B61;
  border-radius: 30px;
  padding: 10px 40px;
  color: #FFFFFF;
  transition: all .20s ease-in;
  border: none;
}

.secondary-btn:hover {
  background-color: #0e71f8;
  border-radius: 30px;
  padding: 10px 40px;
  color: #FFFFFF;
  transition: all .20s ease-in;
  border: none;
}

.outline-primary-btn {
  background-color: #D5E6FF;
  border-radius: 30px;
  padding: 10px 40px;
  color: #0e71f8;
  transition: all .20s ease-in;
  border: none;
}

.outline-primary-btn:hover {
  background-color: #0e71f8;
  border-radius: 30px;
  padding: 10px 40px;
  color: #D5E6FF;
  transition: all .20s ease-in;
  border: none;
}

.blank-btn {
  background-color: transparent;
  border-radius: 30px;
  padding: 10px 40px;
  color: #A7A9B7;
  transition: all .20s ease-in;
  border: none;
}

.danger-btn {
  background-color: #C81B1B;
  border-radius: 30px;
  padding: 10px 40px;
  color: #FFFFFF;
  transition: all .20s ease-in;
  border: none;
}

.danger-btn:hover {
  background-color: #D5E6FF;
  border-radius: 30px;
  padding: 10px 40px;
  color: #C81B1B;
  transition: all .20s ease-in;
  border: none;
}

h1 {
  font-size: 62px !important;
  font-weight: 400 !important;
}

h2 {
  font-size: 52px !important;
  font-weight: 400 !important;
}

h3 {
  font-size: 42px !important;
  font-weight: 300 !important;
}

h4 {
  font-size: 32px !important;
  font-weight: 400 !important;
}

h5 {
  font-size: 22px !important;
  font-weight: 300 !important;
}

h6 {
  font-size: 18px !important;
  font-weight: 300 !important;
}

.select__control {
  border: solid 1px rgb(195, 194, 194) !important;
  font-size: 16px;
  font-weight: 300;
}

.footer-bg {
  background-color: #0e71f8;
  font-family: 'Roboto';
  font-weight: 300;
}


.footer-bg .copyright span {
  font-size: 14px !important;
}





/*///////////////// Mobile Responsiveness ///////////////////*/
@media only screen and (max-width: 500px) {
  .primary-btn-1 {
    background-color: #0e71f8;
    font-size: 14px;
    border-radius: 10px;
    padding: 10px 20px;
    color: #FFFFFF;
    transition: all .20s ease-in;
    border: none;
  }
  
  .primary-btn-1:hover {
    background-color: #D5E6FF;
    font-size: 14px;
    border-radius: 10px;
    padding: 10px 20px;
    color: #0e71f8;
    transition: all .20s ease-in;
    border: none;
  }
  
  .primary-btn {
    background-color: #0e71f8;
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    color: #FFFFFF;
    transition: all .20s ease-in;
    border: none;
  }
  
  .primary-btn:hover {
    background-color: #D5E6FF;
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    color: #0e71f8;
    transition: all .20s ease-in;
    border: none;
  }
  
  .secondary-btn {
    background-color: #052B61;
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    color: #FFFFFF;
    transition: all .20s ease-in;
    border: none;
  }
  
  .secondary-btn:hover {
    background-color: #0e71f8;
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    color: #FFFFFF;
    transition: all .20s ease-in;
    border: none;
  }
  
  .outline-primary-btn {
    background-color: #D5E6FF;
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    color: #0e71f8;
    transition: all .20s ease-in;
    border: none;
  }
  
  .outline-primary-btn:hover {
    background-color: #0e71f8;
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    color: #D5E6FF;
    transition: all .20s ease-in;
    border: none;
  }
  
  .blank-btn {
    background-color: transparent;
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    color: #A7A9B7;
    transition: all .20s ease-in;
    border: none;
  }
  
  .danger-btn {
    background-color: #C81B1B;
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    color: #FFFFFF;
    transition: all .20s ease-in;
    border: none;
  }
  
  .danger-btn:hover {
    background-color: #D5E6FF;
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    color: #C81B1B;
    transition: all .20s ease-in;
    border: none;
  }
  
  h1 {
    font-size: 52px !important;
    font-weight: 400 !important;
  }
  
  h2 {
    font-size: 42px !important;
    font-weight: 400 !important;
  }
  
  h3 {
    font-size: 32px !important;
    font-weight: 300 !important;
  }
  
  h4 {
    font-size: 22px !important;
    font-weight: 400 !important;
  }
  
  h5 {
    font-size: 18px !important;
    font-weight: 300 !important;
  }
  
  h6 {
    font-size: 14px !important;
    font-weight: 300 !important;
  }
}


@media only screen and (max-width: 1400px) and (min-width: 800px) {
  h1 {
    font-size: 46px !important;
    font-weight: 400 !important;
  }
  
  h2 {
    font-size: 36px !important;
    font-weight: 400 !important;
  }
  
  h3 {
    font-size: 30px !important;
    font-weight: 300 !important;
  }
  
  h4 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }
  
  h5 {
    font-size: 18px !important;
    font-weight: 300 !important;
  }
  
  h6 {
    font-size: 16px !important;
    font-weight: 300 !important;
  }
}


@media only screen and (max-width: 800px) {
  h1 {
    font-size: 40px !important;
    font-weight: 400 !important;
  }
  
  h2 {
    font-size: 32px !important;
    font-weight: 400 !important;
  }
  
  h3 {
    font-size: 28px !important;
    font-weight: 300 !important;
  }
  
  h4 {
    font-size: 26px !important;
    font-weight: 400 !important;
  }
  
  h5 {
    font-size: 18px !important;
    font-weight: 300 !important;
  }
  
  h6 {
    font-size: 16px !important;
    font-weight: 300 !important;
  }
}