/* @import url('https://fonts.cdnfonts.com/css/Poppins');
@import url('https://fonts.cdnfonts.com/css/poppins'); */


.brandName1 {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 25px;
    color: #0e71f8;
    vertical-align: bottom;
    letter-spacing: 1px;
}
.brandName {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 25px;
    color: #021228;
    vertical-align: bottom;
    letter-spacing: 1px;
}
.findLawyers {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 25px;
    color: #0e71f8;
    vertical-align: middle;
}
.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 68px;
    margin-bottom: 30px;
}
.backButton {
    background-color: #021228;
    padding: 10px 20px !important;
    border-radius: 5px;
    border: none;
    color: #fff !important;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 18px;
    width: 200px;
}
.switchButtton {
    background: linear-gradient(180deg, #F9A7A7 0%, #EC5D5D 100%);
    padding: 13px 50px 15px 30px;
    border: 1px solid #EE6666;
    border-radius: 10px;
    text-align: left;
}
.switchButtton img {
    width: 30%;
    float: left;
    text-align: left;
    padding-right: 10px;
    margin: auto;
}
.switchButtton .blockText {
    text-align: left;
    line-height: 20px;
    width: 70%;
    float: right;
    font-size: 28px;
    font-family: 'Roboto';
    font-weight: bold;
    margin: auto;
}
.switchButttonInactive {
    background: #F3F3F3;
    opacity: 0.65;
    border-radius: 10px;
    border: none;
    padding: 13px 50px 15px 30px;
    text-align: left;
}
.switchButttonInactive img {
    width: 30%;
    float: left;
    text-align: left;
    padding-right: 10px;
    margin: auto;
}
.switchButttonInactive .blockText {
    text-align: left;
    line-height: 20px;
    width: 70%;
    float: right;
    font-size: 28px;
    font-family: 'Roboto';
    font-weight: bold;
    margin: auto;
}
.blockText label {
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: normal;
}
.switchButtton1 {
    background: linear-gradient(180deg, #A7DBF9 0%, #5DC1EC 100%);
    padding: 13px 50px 15px 30px;
    margin-left: 20px;
    border: 1px solid #69C5EE;
    border-radius: 10px;
    text-align: left;
}
.switchButtton1 img {
    width: 30%;
    float: left;
    text-align: left;
    padding-right: 10px;
    margin: auto;
}
.switchButtton1 .blockText {
    text-align: left;
    line-height: 20px;
    width: 70%;
    float: right;
    font-size: 28px;
    font-family: 'Roboto';
    font-weight: bold;
    margin: auto;
}
.switchButtton1Inactive {
    margin-left: 20px;
    background: #F3F3F3;
    opacity: 0.65;
    border-radius: 10px;
    border: none;
    padding: 13px 50px 15px 30px;
    text-align: left;
}
.switchButtton1Inactive img {
    width: 30%;
    float: left;
    text-align: left;
    padding-right: 10px;
    margin: auto;
}
.switchButtton1Inactive .blockText {
    text-align: left;
    line-height: 20px;
    width: 70%;
    float: right;
    font-size: 28px;
    font-family: 'Roboto';
    font-weight: bold;
    margin: auto;
}
.tabCount {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
}
.tabCount label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 41px;
}
.formLabel {
    flex: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}
.formLabel label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.005em;
    color: #929292;
    position: inherit;
}
.onboardingForm {
    padding: 20px 50px 20px 0px;
}
.inputField {
    font-size: 14px;
    font-family: 'Roboto';
    padding: 12px 20px !important;
    border: #021228 1px solid !important;
    outline: none !important;
}
.textareaField {
    font-size: 14px;
    font-family: 'Roboto';
    padding: 12px 20px !important;
    border: #021228 1px solid !important;
    outline: none !important;
    width: 100%;
}
.formTag {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #C5C5C5;
}
.modalSuccess {
    padding: 30px 50px 50px 50px;
}
.successBig {
    font-family: 'Roboto';
    font-weight: bold;
}
.successSmall {
    font-family: 'Roboto';
    font-size: 14px;
}
.pass {
    margin: -40px 20px 0px 0px;
    text-align: right;
    width:40px;
    float: right;
}
.passValid {
    color: #EC5D5D;
    font-size: 10px;
}
.onboardingForm {
    padding: 30px;
}
.onboardingForm h3 {
    font-size: 40px;
    font-family: 'Roboto';
    margin-bottom: 10px;
}
.onboardingForm h5 {
    font-size: 25px;
    font-family: 'Roboto';
    margin-bottom: 20px;
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
    .title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 800;
        font-size: 35px;
        line-height: 38px;
        margin-bottom: 30px;
    }
    .backButton {
        background-color: #021228;
        padding: 10px 20px !important;
        margin-bottom: 10px;
        border-radius: 5px;
        float: left;
        border: none;
        color: #fff !important;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 18px;
        width: 200px;
    }
    .switchButtton {
        background: linear-gradient(180deg, #F9A7A7 0%, #EC5D5D 100%);
        padding: 13px 50px 15px 30px;
        border: 1px solid #EE6666;
        border-radius: 10px;
        text-align: left;
        margin-bottom: 10px;
    }
    .switchButtton img {
        width: 30%;
        float: left;
        text-align: left;
        padding-right: 10px;
        margin: auto;
    }
    .switchButtton .blockText {
        text-align: left;
        line-height: 20px;
        width: 70%;
        float: right;
        font-size: 28px;
        font-family: 'Roboto';
        font-weight: bold;
        margin: auto;
    }
    .switchButttonInactive {
        background: #F3F3F3;
        opacity: 0.65;
        border-radius: 10px;
        border: none;
        padding: 13px 50px 15px 30px;
        text-align: left;
        margin-bottom: 10px;
    }
    .switchButttonInactive img {
        width: 30%;
        float: left;
        text-align: left;
        padding-right: 10px;
        margin: auto;
    }
    .switchButttonInactive .blockText {
        text-align: left;
        line-height: 20px;
        width: 70%;
        float: right;
        font-size: 28px;
        font-family: 'Roboto';
        font-weight: bold;
        margin: auto;
    }
    .blockText label {
        font-size: 14px;
        font-family: 'Roboto';
        font-weight: normal;
    }
    .switchButtton1 {
        background: linear-gradient(180deg, #A7DBF9 0%, #5DC1EC 100%);
        padding: 13px 50px 15px 30px;
        margin-left: 0px;
        border: 1px solid #69C5EE;
        border-radius: 10px;
        text-align: left;
        margin-bottom: 10px;
    }
    .switchButtton1 img {
        width: 30%;
        float: left;
        text-align: left;
        padding-right: 10px;
        margin: auto;
    }
    .switchButtton1 .blockText {
        text-align: left;
        line-height: 20px;
        width: 70%;
        float: right;
        font-size: 28px;
        font-family: 'Roboto';
        font-weight: bold;
        margin: auto;
    }
    .switchButtton1Inactive {
        margin-left: 0px;
        background: #F3F3F3;
        opacity: 0.65;
        border-radius: 10px;
        border: none;
        padding: 13px 50px 15px 30px;
        text-align: left;
    }
    .switchButtton1Inactive img {
        width: 30%;
        float: left;
        text-align: left;
        padding-right: 10px;
        margin: auto;
    }
    .switchButtton1Inactive .blockText {
        text-align: left;
        line-height: 20px;
        width: 70%;
        float: right;
        font-size: 28px;
        font-family: 'Roboto';
        font-weight: bold;
        margin: auto;
    }
    .tabCount {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 41px;
    }
    .tabCount label {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 41px;
    }
    .formLabel {
        flex: none;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 19px;
    }
    .formLabel label {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.005em;
        color: #929292;
        position: inherit;
    }
    .onboardingForm {
        padding: 20px 20px 20px 0px;
    }
    .inputField {
        font-size: 14px;
        font-family: 'Roboto';
        padding: 12px 20px !important;
        border: #021228 1px solid !important;
        outline: none !important;
    }
    .formTag {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #C5C5C5;
    }
    .modalSuccess {
        padding: 20px;
    }
    .successBig {
        font-family: 'Roboto';
        font-weight: bold;
    }
    .successSmall {
        font-family: 'Roboto';
        font-size: 14px;
    }
    .onboardingBg {
        width: 100%;
        margin-bottom: 50px;
    }
}