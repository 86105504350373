/* @import url('https://fonts.cdnfonts.com/css/Poppins');
@import url('https://fonts.cdnfonts.com/css/poppins'); */

.menuLink {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    color: #021228;
    margin: 0px 10px;
}
.menuLink:hover {
    color: #0e71f8;
}
.divider {
    font-size: 30px;
    color: rgb(199, 199, 199);
    margin-top: -5px;
    padding: 0;
}
/* .aboutContent {
    padding: 0 15%;
} */
.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 68px;
    margin-bottom: 20px;
}
.subTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 68px;
}
.sec1 p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}
.sec1 h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
}
.heroImg {
    float: right;
    margin-right: 5vw;
}
.navArrow {
    font-size: 25px;
    cursor: pointer;
}
.search {
    background-color: #021228;
    padding: 50px 18vw;
}
.search img {
    display: inline;
}
.searchInput {
    padding: 15px !important;
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;
}
.searchButton {
    background: #0e71f8;
    color: #021228;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 24px;
    padding: 10px 80px;
    stroke: 20px #021228;
    stroke-width: 10px;
    border-radius: 10px;
    border: none;
}
.line {
    background: #0e71f8;
    height: 1px;
    width: 50%;
    display: inline-block;
}
.sec2 {
    font-family: 'Roboto';
    margin-left: 50px;
    margin-right: 40px;
}
.sec2 p {
    text-align: justify;
}
.sec2 img {
    padding: 10px 50px 10px 0px;
}
.getLawyer {
    color: #0e71f8;   
    background-color: rgba(228, 178, 0, 0.15);;
    border: 1px solid #0e71f8;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 5px 20px;
}
.getLawyer1 {
    background-color: #0e71f8;
    color: #021228;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px 70px;
    border: 1px solid #4F3900;
    border-radius: 5px;
}
.lawyerName {
    margin-top: -60px;
    padding: 20px 20px 5px 20px;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.614);
}
.firmName {
    padding: 10px;
    font-size: 18px;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 700;
    color: #021228;
}
.links {
    color: #0e71f8;
    font-family: 'Roboto';
}
.testimonial {
    background: #F0F0F0;
    font-family: 'Roboto';
}
.testContent {
    padding: 8% 15% 5% 4%;
}
.testContent h3 {
    font-size: 40px;
    font-weight: bold;
}
.testContent p {
    margin: 50px 0px;
    text-align: justify;
}
.testContent span {
    display: block;
    font-size: 20px;
    font-weight: 600;
}
.button {
    margin-top: 20px !important;
    background-color: #021228;
    padding: 10px 20px !important;
    border-radius: 5px !important;
    border: none !important;
    color: #fff !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    width: 200px !important;
}
.button:hover {
    background-color: #0e71f8;
    padding: 10px 20px !important;
    border-radius: 5px;
    border: none;
    color: #021228 !important;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    width: 200px;
}