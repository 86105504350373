.menuLink {
    font-family: 'Roboto';
    font-weight: 300 !important;
    font-size: 16px;
    color: #021228 !important;
    opacity: 1;
    margin: auto 30px;
    text-decoration: none;
}
.menuLink:hover {
    color: #0e71f8 !important;
    border-bottom: #0e71f8 1px solid;
}
.activeMenuLink {
    font-family: 'Roboto';
    font-weight: 400 !important;
    font-size: 16px;
    color: #0e71f8 !important;
    opacity: 1;
    margin: auto 30px;
    text-decoration: none;
    border-bottom: #0e71f8 1px solid;
}

.signUpBG {
    background-image: url('../assets/signup.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1 !important;
    font-family: 'Poppins' !important;

}

.loginBG {
    font-family: 'Poppins' !important;
}

.signUptabTitle {
    border-bottom: #d6d6dc 2px solid;
}

.signUptabTitle span {
    color: #A7A9B7;
    font-weight: 300;
    padding-bottom: 5px;
    font-size: 15px;
}

.signUptabTitle .active {
    color: #0F71F8;
    font-weight: 400;
    font-size: 15px;
    padding-bottom: 5px;
    border-bottom: #021228 2px solid;
}