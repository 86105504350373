.layout-section {
    height: 100vh;
    width: 100wh;
    font-family: 'Poppins';
}

.sidebar-menu {
    color: grey !important;
    font-size: 22px !important;
    border-bottom: rgb(214, 214, 214) solid 1px;
}

.sidebar-menu:hover {
    background-color: #0e71f8;
    color: #ffffff !important;
    font-size: 22px !important;
    border-bottom: rgb(214, 214, 214) solid 1px;
}
.sidebar-menu-active {
    background-color: #0e71f8;
    color: #ffffff !important;
    font-size: 22px !important;
}

.dashboard-header {
    border-bottom: 1px rgb(214, 214, 214) solid;
}

.dashboard-header span {
    font-weight: 300;
    line-height: 0.5;
    font-size: 16px;
}
.dashboard-header font {
    font-weight: 300;
    line-height: 0.5;
    font-size: 14px;
}