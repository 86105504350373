/* @import url('https://fonts.cdnfonts.com/css/recoleta');
@import url('https://fonts.cdnfonts.com/css/poppins'); */

.section1 img {
    width: 100%;
    height: 100%;
}
/* .section2 {
    margin-top: -100px;
} */

.search-icon-button {
    background-color: #FFC857;
    font-weight: 300 !important;
    height: 150px;
    padding: 30px;
    border: none;
    border-radius: 20px;
}

.search-icon-button1 {
    background-color: #E4D9FF;
    font-weight: 300 !important;
    height: 150px;
    padding: 30px;
    border: none;
    border-radius: 20px;
}

.search-icon-button2 {
    background-color: #D5E6FF;
    font-weight: 300 !important;
    height: 150px;
    padding: 30px;
    border: none;
    border-radius: 20px;
}

.hire-section img {
    margin-bottom: -20px;
}

.search-bg {
    background-image: url('../assets/vector-bg001.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.searchBox {
    background-color: #021228;
    padding: 50px;
    margin: 120px 0;
    border-radius: 20px;
}

.searchInput {
    padding: 15px !important;
    margin-bottom: 30px;
    border-radius: 10px;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.overlay-image {
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    border-radius: 20px;
    opacity: 1;
    width: 100%;
    height: 163px;
    margin-top: -160px;  
}

.faded-overlay {
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, var(--unnamed-color-0f71f8) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #FFFFFF00 0%, #0F71F8 100%) 0% 0% no-repeat padding-box;
mix-blend-mode: multiply;
border-radius: 20px;

}

.boxCard {
    height: 400px;
}
.boxCard .primary-btn {
    margin-top: -100px !important;
}
.boxCard:hover .primary-btn {
    margin-top: 20px !important;
}

.inside-text-button {
    margin-top: -80px;
    margin-bottom: 20px;
    position: absolute;
}
.testimonial-bg {
    background: transparent linear-gradient(109deg, #1D1D1B 0%, #3F3F3A 100%) 0% 0% no-repeat padding-box;
}
/* .section4 {
    margin-top: -150px;
} */


@media only screen and (max-width: 1400px) and (min-width: 800px) {
    .section1 img {
        width: 100%;
        height: 100%;
    }
    .section2 {
        margin-top: 0px;
    }
    .search-icon-button {
        background-color: #FFC857;
        font-weight: 300 !important;
        height: 180px;
        padding: 30px;
        border: none;
        border-radius: 20px;
    }
    
    .search-icon-button1 {
        background-color: #E4D9FF;
        font-weight: 300 !important;
        height: 180px;
        padding: 30px;
        border: none;
        border-radius: 20px;
    }
    
    .search-icon-button2 {
        background-color: #D5E6FF;
        font-weight: 300 !important;
        height: 180px;
        padding: 30px;
        border: none;
        border-radius: 20px;
    }
    .hire-section button {
        padding: 15px 50px !important;
    }
}

@media only screen and (max-width: 800px) and (min-width: 500px) {
    .section1 img {
        width: 80%;
        height: 80%;
        margin-bottom: 20px;
    }
    .hire-section button {
        padding: 15px 50px !important;
        margin-bottom: 30px;
    }
    
}


@media only screen and (max-width: 500px) {
    .section1 img {
        width: 100%;
        height: 100%;
    }
    .section2 {
        margin-top: 40px;
    }
    .section2 img {
        width: 100%;
        margin-bottom: 30px;
    }
    .section2 button {
        margin-bottom: 20px;
    }
    .section2 button img {
        width: auto;
    }
    .section4 {
        margin-top: -50px;
    }
    .boxCard {
        height: 400px;
    }
    .boxCard .primary-btn {
        margin-top: -100px !important;
    }
    .boxCard:hover .primary-btn {
        margin-top: 20px !important;
    }
}