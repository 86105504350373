/* @import url('https://fonts.cdnfonts.com/css/recoleta');
@import url('https://fonts.cdnfonts.com/css/poppins'); */

.searchBg {
    background-image: url('../images/Rectangle 37.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.searchBg h2 {
    text-align: center;
    font-family: 'Roboto';
    font-size: 55px;
    color: #fff;
    padding: 100px 0px;
    margin: 0px;
}
.findLawyers {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 25px;
    color: #0e71f8;
    vertical-align: middle;
}
.line {
    background: #0e71f8;
    height: 1px;
    width: 50%;
    display: inline-block;
}
.search {
    background-color: #021228;
    /* margin: 5rem 0px;
    padding: 50px 0px; */
}
.search img {
    display: inline;
}
.searchInput {
    padding: 15px !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}
.searchButton {
    background: #0e71f8;
    color: #021228;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 24px;
    padding: 10px 80px;
    stroke: 20px #021228;
    stroke-width: 10px;
    border-radius: 10px;
    border: none;
}
.signUptext {
    font-family: 'Poppins' !important;
}
.getLawyer {
    color: #0e71f8;  
    background-color: rgba(228, 178, 0, 0.15);;
    border: 1px solid #0e71f8;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 5px 20px;
}
.getLawyer1 {
    background-color: #0e71f8;
    color: #021228;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px 70px;
    border: 1px solid #4F3900;
    border-radius: 5px;
}
.lawyerList {
    margin: 5px;
}
.lawyerName {
    position: absolute;
    margin-left: 5px;
    margin-right: 10px;
    bottom: 0;
    padding: 15px;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
    color: #FFF;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.614);
}
.firmName {
    padding: 10px;
    font-size: 18px;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 700;
    color: #021228;
}
.links {
    color: #0e71f8;
    font-family: 'Roboto';
}



@media only screen and (max-width: 1200px) and (min-width: 700px) {
    .searchInput {
        padding: 15px !important;
        font-family: Poppins !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        margin-bottom: 10px !important;
    }
}

@media only screen and (max-width: 600px) and (min-width: 300px) {

    .searchBg h2 {
        text-align: center;
        font-family: 'Roboto';
        font-size: 40px;
        color: #fff;
        padding: 100px 0px;
        margin: 0px;
    }
    
    .search {
        background-color: #021228 !important;
        margin: 2rem 0px !important;
        padding: 50px 0px !important;
    }
    .search img {
        display: inline;
    }
    .searchInput {
        padding: 15px !important;
        font-family: Poppins !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        margin-bottom: 10px !important;
    }
    .searchButton {
        background: #0e71f8;
        color: #021228;
        font-family: 'Roboto';
        font-weight: bold;
        font-size: 24px;
        padding: 10px 80px;
        stroke: 20px #021228;
        stroke-width: 10px;
        border-radius: 10px;
        border: none;
    }
    .line {
        background: #0e71f8;
        height: 1px;
        width: 50%;
        display: inline-block;
    }
}